<template>
  <div class="create-table-page">
    <header class="header">
      <div class="top-bar">
        <div class="container">
          <div class="logo">
            <img src="@/assets/optionsTechnology-logo.svg" alt="Options IT Logo" />
          </div>
        </div>
      </div>
      <div class="nav-bar" v-if="isLoggedIn">
        <div class="container">
          <NavBar />
        </div>
      </div>
    </header>
    <div class="create-table-container">
      <h1 class="page-title">Create New App Deploy Table</h1>
      <p class="description">
        Enter the client code and create a new Azure storage table. You can also add applications to the newly created table.
      </p>

      <!-- Message display section -->
      <div v-if="message.text" :class="['message', message.type]">
        {{ message.text }}
      </div>

      <div class="actions">
        <input v-model="clientCode" placeholder="Enter Client Code" class="input-element" />
        <button @click="createNewClientTable" class="primary-button">Create New Client Table</button>
        <button @click="updateNewClientTable" class="primary-button">Add Applications</button>
        <button @click="returnToTables" class="secondary-button">Return to Tables</button>
      </div>

      <div v-if="selectedTable && tableContents.length" class="table-wrapper">
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th>Select</th>
                <th v-for="header in tableHeaders" :key="header" @click="sortTable(header)">
                  {{ header }} <span v-if="sortColumn === header">{{ sortOrder === 'asc' ? '▲' : '▼' }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in sortedTableContents" :key="row.PartitionKey + '-' + row.rowKey">
                <td>
                  <input type="checkbox" :value="row" v-model="selectedApplications" />
                </td>
                <td v-for="header in tableHeaders" :key="header" class="table-cell">{{ row[header] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import NavBar from '@/views/components/NavBar.vue';
import { msalInstance } from '@/auth.js';
import { getTableContents, createClientTable, updateClientTable } from '@/azure-devops-api.js';
import { mapState } from 'vuex';

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      selectedTable: 'GENWorkstationApps',
      tableContents: [],
      tableHeaders: [],
      sortColumn: '',
      sortOrder: 'asc',
      clientCode: '',
      selectedApplications: [], // Change to array to handle multiple selections
      newTableName: '',
      message: {
        text: '',
        type: '', // 'success' or 'error'
      },
    };
  },
  computed: {
    ...mapState(['isLoggedIn']),
    sortedTableContents() {
      if (!this.sortColumn) return this.tableContents;
      return this.tableContents.slice().sort((a, b) => {
        const aValue = a[this.sortColumn];
        const bValue = b[this.sortColumn];
        if (aValue < bValue) return this.sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return this.sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!msalInstance.getAllAccounts().length) {
      next('/login');
    } else {
      next();
    }
  },
  async created() {
    try {
      const contents = await getTableContents(this.selectedTable);
      this.tableContents = contents;
      const headers = Object.keys(contents[0]);
      this.tableHeaders = ['AppName', 'Version', ...headers.filter(h => h !== 'AppName' && h !== 'Version')];
    } catch (error) {
      console.error("Error fetching table contents:", error);
    }
  },
  methods: {
    async createNewClientTable() {
      if (!this.clientCode) {
        alert('Please enter a client code.');
        return;
      }
      this.newTableName = `${this.clientCode}WorkstationApps`;
      try {
        const response = await createClientTable(this.newTableName);
        console.log('Response:', response); // Log the full response for debugging

        // Handle the response based on the status
        if (response.status === 201) {
          this.message.text = response.message;
          this.message.type = 'success';
        } else if (response.status === 409) {
          this.message.text = response.message;
          this.message.type = 'error';
        } else {
          this.message.text = `Unexpected error: ${response.message}`;
          this.message.type = 'error';
        }
      } catch (error) {
        console.error('Error creating new client table:', error);
        // Display the full error message or object
        this.message.text = `Error creating table: ${JSON.stringify(error)}`;
        this.message.type = 'error';
      }
    },
    async updateNewClientTable() {
      if (!this.selectedApplications.length) {
        alert('Please select at least one application.');
        return;
      }
      if (!this.newTableName) {
        alert('No table to update. Please create a table first.');
        return;
      }
      try {
        for (let app of this.selectedApplications) {
          const nextRowKey = await this.getNextRowKeyForSelectedTable();
          const updatedApp = { ...app, rowKey: nextRowKey };

          const response = await updateClientTable(this.newTableName, updatedApp);
          if (response.status !== 200) {
            this.message.text = `Error updating table: ${response.message}`;
            this.message.type = 'error';
            return;
          }
        }
        this.message.text = `Table '${this.newTableName}' updated successfully with selected applications.`;
        this.message.type = 'success';
      } catch (error) {
        console.error('Error updating new client table:', error);
        this.message.text = `Error updating table: ${JSON.stringify(error)}`;
        this.message.type = 'error';
      }
    },
    async getNextRowKeyForSelectedTable() {
      try {
        const contents = await getTableContents(this.newTableName); // Fix: Change 'this.selectedTable' to 'this.newTableName'
        console.log('Fetched table contents for selected table:', contents);

        if (contents.length === 0) {
          return '0001';
        }

        const rowKeys = contents.map(item => {
          if (item.rowKey && typeof item.rowKey === 'string') { // Fix: Change 'rowKey' to 'rowKey'
            const parsedKey = parseInt(item.rowKey, 10); // Fix: Change 'rowKey' to 'rowKey'
            if (isNaN(parsedKey)) {
              console.error('Invalid rowKey encountered, non-numeric:', item.rowKey); // Fix: Change 'rowKey' to 'rowKey'
              return 0;
            }
            return parsedKey;
          } else {
            console.error('rowKey missing or not a string:', item); // Fix: Change 'rowKey' to 'rowKey'
            return 0;
          }
        });

        const maxRowKey = Math.max(...rowKeys);
        const nextRowKey = (maxRowKey + 1).toString().padStart(4, '0');
        console.log('Calculated next rowKey for selected table:', nextRowKey);
        return nextRowKey;
      } catch (error) {
        console.error("Error fetching rowKey for selected table:", error); // Fix: Change 'rowKey' to 'rowKey'
        return '0001';
      }
    },
    sortTable(column) {
      if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortOrder = 'asc';
      }
    },
    returnToTables() {
      this.$router.push('/storage-tables-list');
    },
  },
};
</script>



  
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.header {
  background: #0E223B;
  color: #fff;
}

.top-bar {
  background: #ffffff;
  padding: 20px 0;
  text-align: center;
}

.top-bar .logo {
  text-align: left;
}

.top-bar .logo img {
  width: 150px;
  margin-left: 20px;
}

.nav-bar {
  background: #0E223B;
  padding: 10px 0;
}

.nav-bar a {
  color: #fff;
  margin: 0 15px;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-bar a:hover {
  color: #38B35F;
}

.create-table-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  text-align: center;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.page-title {
  font-size: 2rem;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
}

.description {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

.actions {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.input-element {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 150px;
}

.primary-button, .secondary-button {
  font-family: 'Roboto', sans-serif;
  margin: 5px;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.primary-button {
  background-color: #38B35F;
  color: white;
  text-decoration: none; /* Remove underline */
}

.primary-button:hover {
  background-color: #1c7ed6;
}

.secondary-button {
  background-color: #555;
  color: white;
}

.secondary-button:hover {
  background-color: #444;
}

.button-link {
  display: inline-block; /* Ensure it's block-level */
  text-align: center;
}

.table-wrapper {
  width: 100%;
  overflow: hidden;
}

.table-scroll-container {
  position: relative;
  width: 100%;
}

.table-scrollbar {
  overflow-x: auto;
  overflow-y: hidden;
  height: 20px;
}

.table-scroll-content {
  height: 1px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #ddd;
  font-family: 'Roboto', sans-serif;
}

table {
  width: 100%;
  margin: 20px 0;
  border-collapse: collapse;
  min-width: 600px;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* Adjust this value as needed */
}

th {
  background-color: #f4f4f4;
  cursor: pointer;
}

th span {
  font-size: 0.8rem;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

.table-cell {
  max-width: 150px; /* Adjust this value as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message {
  margin: 20px 0;
  padding: 10px;
  border-radius: 4px;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
  font-family: 'Roboto', sans-serif;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  font-family: 'Roboto', sans-serif;
}
</style>
