<template>
    <div v-if="show" class="modal-overlay" @click.self="close">
      <div class="modal-content">
        <button class="close-button" @click="close">&times;</button>
        <h2>{{ areaTitle }} Policies</h2>
  
        <div class="policy-section">
          <h3>Passing Policies</h3>
          <div class="policy-list" v-if="passingPolicies.length">
            <ul>
              <li v-for="(policy, index) in passingPolicies" :key="index">{{ policy }}</li>
            </ul>
          </div>
          <p v-else>No passing policies available.</p>
        </div>
  
        <div class="policy-section">
          <h3>Failing Policies</h3>
          <div class="policy-list" v-if="failingPolicies.length">
            <ul>
              <li v-for="(policy, index) in failingPolicies" :key="index">{{ policy }}</li>
            </ul>
          </div>
          <p v-else>No failing policies available.</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      show: {
        type: Boolean,
        required: true,
      },
      areaTitle: {
        type: String,
        required: true,
      },
      passingPolicies: {
        type: Array,
        required: true,
      },
      failingPolicies: {
        type: Array,
        required: true,
      },
    },
    methods: {
      close() {
        this.$emit("close");
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 800px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    color: #555;
    transition: color 0.3s;
  }
  
  .close-button:hover {
    color: #000;
  }
  
  h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .policy-section {
    margin-bottom: 20px;
  }
  
  .policy-section h3 {
    font-size: 1.2rem;
    color: #444;
    margin-bottom: 10px;
  }
  
  .policy-list {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .policy-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .policy-list li {
    font-size: 0.95rem;
    color: #555;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .policy-list li:last-child {
    border-bottom: none;
  }
  
  p {
    color: #777;
    font-size: 0.9rem;
    margin: 10px 0 0;
  }
  </style>
  