<template>
    <div class="projects-page">
      <header class="header">
        <div class="top-bar">
          <div class="container">
            <div class="logo">
              <img src="@/assets/optionsTechnology-logo.svg" alt="Options IT Logo" />
            </div>
          </div>
        </div>
        <div class="nav-bar" v-if="isLoggedIn">
          <div class="container">
            <NavBar />
          </div>
        </div>
      </header>
      <div class="projects-container">
        <h1 class="page-title">Projects</h1>
        <div class="projects-grid">
          <router-link v-for="project in projects" :key="project.id"
              :to="`/associated-pipelines/${encodeURIComponent(project.name)}/${project.id}`" class="project-card">
              <h2 class="project-title">{{ project.name }}</h2>
          </router-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import NavBar from '@/views/components/NavBar.vue';
  import { msalInstance } from '@/auth.js';
  import { fetchProjects } from '@/azure-devops-api.js';
  import { mapState } from 'vuex';
  
  export default {
    components: {
      NavBar,
    },
    data() {
      return {
        projects: [],
      };
    },
    computed: mapState(['isLoggedIn']),
    beforeRouteEnter(to, from, next) {
      if (!msalInstance.getAllAccounts().length) {
        next('/login');
      } else {
        next();
      }
    },
    async created() {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length) {
        const accessTokenRequest = {
          account: accounts[0],
          scopes: ['499b84ac-1321-427f-aa17-267ca6975798/user_impersonation'],
        };
  
        try {
          const response = await msalInstance.acquireTokenSilent(accessTokenRequest);
          this.projects = await fetchProjects(response.accessToken);
        } catch (error) {
          console.error('Error fetching projects:', error);
        }
      }
    },
    methods: {
      async login() {
        try {
          const currentAccount = msalInstance.getActiveAccount();
          await msalInstance.handleRedirectPromise();
          if (!currentAccount) {
            await msalInstance.loginPopup({
              scopes: [
                "openid",
                "profile",
                "email",
                "offline_access",
                "User.Read",
                "499b84ac-1321-427f-aa17-267ca6975798/user_impersonation",
              ],
            });
          }
          this.$router.push("/azure-ado-projects");
        } catch (error) {
          console.error("Login error:", error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap');
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
  }
  
  .container {
    width: 100%;
    margin: 0 auto;
  }
  
  .header {
    background: #0E223B;
    color: #fff;
  }
  
  .top-bar {
    background: #ffffff;
    padding: 20px 0;
    text-align: center;
  }
  
  .top-bar .logo {
    text-align: left;
  }
  
  .top-bar .logo img {
    width: 150px;
    margin-left: 20px;
  }
  
  .nav-bar {
    background: #0E223B;
    padding: 10px 0;
  }
  
  .nav-bar a {
    color: #fff;
    margin: 0 15px;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .nav-bar a:hover {
    color: #38B35F;
  }
  
  .projects-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
  }
  
  .page-title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .project-card {
    background-color: #0E223B;
    font-family: 'Roboto', sans-serif;
    color: #fff;
    padding: 1.5rem;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s;
    text-decoration: none;
  }
  
  .project-card:hover {
    background-color: #1e90ff;
  }
  
  .project-title {
    font-size: 1rem;
    margin-bottom: 1rem;
    text-decoration: none;
  }
  
  .pipelines-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .pipeline-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .pipeline-name {
    font-size: 1.1rem;
    margin-left: 0.5rem;
    text-decoration: none;
  }
  </style>
  