<template>
    <label class="container">
      <input
        v-bind="$attrs"
        class="input"
        type="checkbox"
        :checked="checked"
        @change="$emit('update:checked', $event.target.checked)"
      />
      <span class="SwitchToggle"></span>
      <span class="label">{{ label }}</span>
    </label>
  </template>
  
  <script>
  export default {
    name: "SwitchToggle",
    props: {
      label: {
        type: String,
        required: true,
      },
      checked: {
        type: Boolean,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  .container {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .label {
    margin-left: 12px;
    color: #1a202c;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .SwitchToggle {
    --SwitchToggle-container-width: 50px;
    --SwitchToggle-size: calc(var(--SwitchToggle-container-width) / 2);
    --light-gray: #e2e8f0;
    --gray: #cbd5e0;
    --dark-gray: #a0aec0;
    --teal: #4fd1c5;
    --dark-teal: #319795;
    display: flex;
    align-items: center;
    position: relative;
    height: var(--SwitchToggle-size);
    flex-basis: var(--SwitchToggle-container-width);
    border-radius: var(--SwitchToggle-size);
    background-color: var(--light-gray);
    flex-shrink: 0;
    transition: background-color 0.25s ease-in-out;
  }
  .SwitchToggle::before {
    content: "";
    position: absolute;
    left: 1px;
    height: calc(var(--SwitchToggle-size) - 4px);
    width: calc(var(--SwitchToggle-size) - 4px);
    border-radius: 9999px;
    background-color: white;
    border: 2px solid var(--light-gray);
    transition: transform 0.375s ease-in-out;
  }
  .input:checked + .SwitchToggle {
    background-color: var(--teal);
  }
  .input:checked + .SwitchToggle::before {
    border-color: var(--teal);
    transform: translateX(
      calc(var(--SwitchToggle-container-width) - var(--SwitchToggle-size))
    );
  }
  .input:focus + .SwitchToggle::before {
    border-color: var(--gray);
  }
  .input:focus:checked + .SwitchToggle::before {
    border-color: var(--dark-teal);
  }
  .input:disabled + .SwitchToggle {
    background-color: var(--gray);
  }
  .input:disabled + .SwitchToggle::before {
    background-color: var(--dark-gray);
    border-color: var(--dark-gray);
  }
  </style>
  