<!-- components/LoadingBar.vue -->
<template>
    <div class="loading-bar-container" v-if="loading">
      <p>Loading... {{ progressPercentage }}%</p>
      <progress :value="progressPercentage" max="100"></progress>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      progressPercentage: {
        type: Number,
        default: 0
      }
    }
  };
  </script>
  
  <style scoped>
  .loading-bar-container {
    text-align: center;
    margin-top: 20px;
  }
  
  progress {
    width: 100%;
    height: 20px;
    border-radius: 5px;
  }
  </style>
  