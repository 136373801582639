<template>
    <div class="pipelines-page">
      <header class="header">
        <div class="top-bar">
          <div class="container">
            <div class="logo">
              <img src="@/assets/optionsTechnology-logo.svg" alt="Options IT Logo" />
            </div>
          </div>
        </div>
        <div class="nav-bar" v-if="isLoggedIn">
          <div class="container">
            <NavBar />
          </div>
        </div>
      </header>
      <div class="pipelines-container">
        <h1>Associated Pipelines</h1>
        <ul class="pipelines-list">
          <li v-for="pipeline in pipelines" :key="pipeline.id" class="pipeline-item">
            <div class="pipeline-info">
              <div class="info-item">
                <h3>Client</h3>
                <p>{{ pipeline.folder.charAt(0) === '\\' ? pipeline.folder.slice(1) : pipeline.folder }}</p>
              </div>
              <div class="info-item">
                <h3>Pipeline</h3>
                <p>{{ pipeline.name }}</p>
              </div>
              <div class="info-item">
                <h3>Application</h3>
                <select v-model="pipeline.selectedApp">
                  <option disabled value="">Please select an app</option>
                  <option value="7zip">7zip</option>
                  <option value="GoogleChrome">Google Chrome</option>
                </select>
              </div>
              <div class="button-container">
                <button @click="triggerBuild(pipeline)" :disabled="!pipeline.selectedApp" class="run-button">
                  Run Pipeline
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import NavBar from '@/views/components/NavBar.vue';
  import { msalInstance } from '@/auth.js';
  import { fetchPipelinesbyName } from '@/azure-devops-api.js';
  import axios from 'axios'; // Make sure axios is installed and imported
  import { mapState } from 'vuex';
  
  export default {
    components: {
      NavBar,
    },
    data() {
      return {
        pipelines: [],
        projectId: '', // added this line
      };
    },
    computed: mapState(['isLoggedIn']),
    methods: {
      async triggerBuild(pipeline) {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length) {
          const accessTokenRequest = {
            account: accounts[0],
            scopes: ['499b84ac-1321-427f-aa17-267ca6975798/user_impersonation'],
          };
          try {
            const response = await msalInstance.acquireTokenSilent(accessTokenRequest);
            const apiUrl = `https://dev.azure.com/cyaniccloud/${this.projectId}/_apis/pipelines/${pipeline.id}/runs?api-version=7.1-preview.1`;
            const headers = {
              Authorization: `Bearer ${response.accessToken}`,
              'Content-Type': 'application/json',
            };
            const body = {
              stagesToSkip: [],
              resources: {
                repositories: {
                  self: {
                    refName: 'refs/heads/main',
                  },
                },
              },
              templateParameters: {
                AppList: `- ${pipeline.selectedApp}`,
              },
              variables: {},
            };
            await axios.post(apiUrl, body, { headers });
          } catch (error) {
            console.error('Error triggering build:', error);
          }
        }
      },
    },
    async created() {
      const projectName = decodeURIComponent(this.$route.params.projectName);
      this.projectId = this.$route.params.projectId; // retrieve project ID from route params and assign it to component data
  
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length) {
        const accessTokenRequest = {
          account: accounts[0],
          scopes: ['499b84ac-1321-427f-aa17-267ca6975798/user_impersonation'],
        };
  
        try {
          const response = await msalInstance.acquireTokenSilent(accessTokenRequest);
          this.pipelines = await fetchPipelinesbyName(response.accessToken, projectName);
        } catch (error) {
          console.error('Error fetching pipelines:', error);
        }
      }
    },
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@400;500;700&display=swap');
  
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
  }
  
  .container {
    width: 100%;
    margin: 0 auto;
  }
  
  .header {
    background: #0E223B;
    color: #fff;
  }
  
  .top-bar {
    background: #ffffff;
    padding: 20px 0;
    text-align: center;
  }
  
  .top-bar .logo {
    text-align: left;
  }
  
  .top-bar .logo img {
    width: 150px;
    margin-left: 20px;
  }
  
  .nav-bar {
    background: #0E223B;
    padding: 10px 0;
  }
  
  .nav-bar a {
    color: #fff;
    margin: 0 15px;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .nav-bar a:hover {
    color: #38B35F;
  }
  
  .pipelines-container {
    max-width: 1650px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .pipelines-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .pipeline-item {
    background-color: #F1F2F3;
    font-family: 'Roboto', sans-serif;
    color: #2A2C53;
    padding: 20px;
    border-radius: 4px;
    text-align: left;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .pipeline-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .info-item {
    display: flex;
    flex-direction: column;
  }
  
  h3 {
    margin: 0;
    margin-bottom: 0.5rem;
  }
  
  .run-button {
    padding: 0.5rem 1rem;
    color: #fff;
    background-color: #38B35F;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .run-button:hover {
    background-color: #0056b3;
  }
  
  .run-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  </style>
  