<template>
    <div class="status-tiles">
      <div class="tile tile-pass">
        <h3>Total Passes</h3>
        <p>{{ totalPasses }}</p>
      </div>
      <div class="tile tile-fail">
        <h3>Total Fails</h3>
        <p>{{ totalFails }}</p>
      </div>
    </div>
    <hr class="divider" />
  </template>
  
  <script>
  export default {
    name: "StatusTiles",
    props: {
      totalPasses: {
        type: Number,
        required: true,
      },
      totalFails: {
        type: Number,
        required: true,
      },
    },
    mounted() {
      console.log("Mounted StatusTiles component");
      console.log("Total Passes:", this.totalPasses);
      console.log("Total Fails:", this.totalFails);
    },
  };
  </script>
  
  <style scoped>
  .status-tiles {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .tile {
    flex: 1;
    padding: 20px;
    border-radius: 8px;
    color: #fff;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .tile-pass {
    background-color: #38b35f;
  }
  
  .tile-fail {
    background-color: #e74c3c;
  }
  
  .divider {
    width: 100%;
    height: 1px;
    background-color: #ccc;
    margin: 20px 0;
  }
  </style>
  