<template>
  <div class="stats-tile">
    <h3>{{ areaTitle }}</h3>
    <div class="status-summary">
      <div class="tile-status pass">
        <p>Total Policies Passing: {{ policyCounts?.totalPasses || 0 }}</p>
      </div>
      <div class="tile-status fail">
        <p>Total Policies Failing: {{ policyCounts?.totalFails || 0 }}</p>
      </div>
    </div>
    <button @click="showMoreInfo">More Info</button>
  </div>
</template>

<script>
export default {
  props: {
    areaTitle: {
      type: String,
      required: true,
    },
    policyCounts: {
      type: Object,
      required: true,
      default: () => ({
        totalPasses: 0,
        totalFails: 0,
      }),
    },
    passingPolicies: {
      type: Array,
      required: false,
      default: () => [],
    },
    failingPolicies: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  methods: {
    showMoreInfo() {
      this.$emit("more-info", {
        areaTitle: this.areaTitle,
        passingPolicies: this.passingPolicies,
        failingPolicies: this.failingPolicies,
      });
    },
  },
};
</script>

<style scoped>
/* Main container for the stats tile */
.stats-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 10px;
  width: 220px; /* Adjusted width for better layout */
  max-width: 100%;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

/* Hover effect for a slightly elevated look */
.stats-tile:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

/* Title styling */
.stats-tile h3 {
  color: #2a2c53;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 12px;
  text-align: center;
  line-height: 1.2;
}

/* Container for pass/fail counts */
.status-summary {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

/* Individual pass/fail status boxes */
.tile-status {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 0.95rem;
  font-weight: 600;
  color: #333;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 100px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tile-status.pass {
  border-left: 5px solid #38b35f;
  color: #38b35f;
}

.tile-status.fail {
  border-left: 5px solid #e74c3c;
  color: #e74c3c;
}

/* Button styling */
button {
  margin-top: 12px;
  background-color: #2a2c53;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}

button:hover {
  background-color: #38b35f;
  transform: translateY(-2px);
}

button:active {
  background-color: #1e8e47;
  transform: translateY(0);
}
</style>


