<template>
  <div class="modal" v-if="show">
    <div class="modal-content">
      <!-- Logo at the top -->
      <div class="logo-container">
        <img src="@/assets/optionsTechnology-logo.svg" alt="Options IT Logo" />
      </div>

      <h2>Export Reconcile Report</h2>
      
      <!-- Custom Logo Upload -->
      <div class="form-group">
        <label for="logoUpload">Custom Logo (optional)</label>
        <input type="file" id="logoUpload" @change="onFileChange" />
      </div>
      
      <!-- Support Message -->
      <div class="form-group">
        <label for="supportDetails">Message that appears in last page of report (optional)</label>
        <textarea
          id="supportDetails"
          v-model="supportMessage"
          placeholder="Enter your support/contact details here..."
        ></textarea>
      </div>
      
      <!-- Export Option Toggle -->
      <div class="form-group toggle-group">
        <div class="toggle-container">
          <SwitchToggle v-model:checked="exportAllResults" />
          <span>{{ exportAllResults ? "Export All Results" : "Export Failed Results Only" }}</span>
        </div>
      </div>
      
      <!-- Modal Footer -->
      <div class="modal-footer">
        <button class="btn btn-cancel" @click="closeModal">Close</button>
        <button class="btn btn-confirm" @click="exportReport">Export</button>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchToggle from "@/views/components/SwitchToggle.vue"; // Import the toggle component

export default {
  components: {
    SwitchToggle, // Register the toggle component
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      supportMessage: "",
      logoFile: null,
      exportAllResults: true, // Default option for exporting all results
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.logoFile = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    exportReport() {
      this.$emit("export", {
        supportMessage: this.supportMessage,
        logoFile: this.logoFile,
        exportAllResults: this.exportAllResults, // Pass the selected option
      });
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  width: 450px;
  max-width: 90%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  font-family: "Roboto", sans-serif;
  color: #333;
  text-align: center; /* Center-align content */
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo-container img {
  max-width: 150px;
}

h2 {
  font-size: 1.5rem;
  color: #2a2c53;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

label {
  font-size: 1rem;
  color: #2a2c53;
  margin-bottom: 8px;
  display: block;
}

input[type="file"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  color: #333;
}

textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none;
  font-family: "Roboto", sans-serif;
  color: #333;
  min-height: 80px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-cancel {
  background-color: #ccc;
  color: #333;
}

.btn-confirm {
  background-color: #38b35f;
  color: white;
}

.btn:hover {
  transform: translateY(-2px);
}

.btn-cancel:hover {
  background-color: #999;
}

.btn-confirm:hover {
  background-color: #2a2c53;
}

.toggle-group {
  margin-bottom: 20px;
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 55px;
}

.toggle-container span {
  line-height: 1.5;
  font-size: 1rem;
  color: #333;
}

/* Responsive Design */
@media (max-width: 600px) {
  .modal-content {
    padding: 20px;
  }

  h2 {
    font-size: 1.2rem;
  }

  .btn {
    font-size: 0.9rem;
    padding: 8px 15px;
  }
}
</style>
