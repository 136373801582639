import { createStore } from "vuex";

export default createStore({
  state: {
    isLoggedIn: JSON.parse(localStorage.getItem("isLoggedIn")) || false,
    loggedInUser: JSON.parse(localStorage.getItem("loggedInUser")) || null,
    userPhoto: localStorage.getItem("userPhoto") || null,
  },
  getters: {},
  mutations: {
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
      localStorage.setItem("isLoggedIn", JSON.stringify(value));
    },
    setLoggedInUser(state, user) {
      state.loggedInUser = user;
      localStorage.setItem("loggedInUser", JSON.stringify(user));
    },
    setUserPhoto(state, photoUrl) {
      state.userPhoto = photoUrl;
      localStorage.setItem("userPhoto", photoUrl);
    },
    logout(state) {
      state.isLoggedIn = false;
      state.loggedInUser = null;
      state.userPhoto = null;
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("loggedInUser");
      localStorage.removeItem("userPhoto");
    },
  },
  actions: {},
  modules: {},
});
